<template>
  <main class="mb-16 px-4 max-w-sm md:max-w-3xl lg:max-w-6xl mx-auto">
    <h2 class="my-10 text-2xl font-semibold text-gray-700 dark:text-gray-200 flex">
      <router-link class="w-10 block" tag="a" :to="{ name: 'producers'}" >
        <svg
          class="h-8 w-8 fill-current text-gray-700 dark:text-gray-200"
          style="enable-background:new 0 0 512 512;"
          version="1.1"
          viewBox="0 0 512 512"
          xml:space="preserve"
        >
          <polygon
            points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "
          />
        </svg>
      </router-link>
      {{ title_page }}
    </h2>
    
    <form @submit.prevent="submit" enctype="multipart/form-data" class="bg-white rounded-lg shadow-md dark:bg-gray-800 pb-12">
      <hr class="rounded-t-lg mb-4 lg:mb-10 border-g dark:border-gray-600"/>
      <div class="max-w-2xl mx-auto pt-8 mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-3">
          <label
            for="producerName"
            class="block text-sm font-medium text-gray-700 dark:text-grey-H-400"
          >
            producerName
          </label>
          <div class="mt-1">
            <input
              type="text"
              v-model="producerName"
              name="producerName"
              id="producerName"
              autocomplete="given-name"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <label
            for="country"
            class="block text-sm font-medium text-gray-700 dark:text-grey-H-400"
          >
            Gender
          </label>
          <div class="mt-1">
            <select
              v-model="genderId"
              id="country"
              name="country"
              autocomplete="country"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            >
              <option value="1">Male</option>
              <option value="2">Female</option>
              </select>
          </div>
        </div>

        <div class="sm:col-span-6">
          <label
            for="ProducerDec"
            class="block text-sm font-medium text-gray-700 dark:text-grey-H-400"
          >
            ProducerDec
          </label>
          <div class="mt-1">
            <textarea
              v-model="producerDec"
              id="ProducerDec"
              name="ProducerDec"
              rows="3"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            ></textarea>
          </div>
          <p class="mt-2 text-sm text-gray-500">
            Write a few sentences producerDec yourself.
          </p>
        </div>

        <div class="sm:col-span-6">
          <label
            for="Product_photo"
            class="block text-sm font-medium text-gray-700 dark:text-grey-H-400"
          >
            producer photo
          </label>
          <label class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-black hover:text-white duration-300">
              <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
              </svg>
              <span class="mt-2 text-base leading-normal">Select a file</span>
              <input type='file' class="hidden" @change="selectFile($event)" />
          </label>
        </div>

        <div v-if="imageName" class="sm:col-span-6 flex justify-center items-center mt-4">
          <img
            :src="imageName"
            alt=""
            class="object-cover w-52 h-52 rounded-xl"
          />
        </div>

      </div>

      <div class="mt-4 mb-10 w-full flex items-center justify-center" >
        <button
          class="uppercase w-56 text-centeruppercase py-2 text-xs md:text-sm font-medium leading-5 text-white transition-colors duration-300 bg-black border border-transparent rounded-lg hover:bg-gray-700 focus:outline-none"
          :class="
            $v.$invalid
              ? 'cursor-not-allowed '
              : ' hover:bg-gray-700 focus:outline-none'
          "
          @click.prevent="submit"
          id="submit"
          type="submit"
          :disabled="$v.$invalid"
        >
          {{ submit_text }}
        </button>
      </div>
    
    </form>

   <div v-if="showAlert" class="absolute inset-0 h-full w-full bg-gray-700 bg-opacity-75 flex justify-center items-center">
      <div v-if="loading" class="h-64 flex justify-center items-center ">
        <svg class="w-10 h-w-10 rounded-full" viewBox="0 0 38 38" stroke="#fff">
          <g fill="none">
            <g transform="translate(1 1)" stroke-width="2">
              <circle
                stroke="#E1E7EC"
                stroke-opacity=".5"
                cx="18"
                cy="18"
                r="18"
              />
              <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
      
      <div v-else class="w-1/3 bg-cool-gray-100 rounded">
        <div
          v-if="Successed"
          class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50"
        >
          <div class="flex justify-end">
            <button @click="showAlert = false" class="focus:outline-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="flex justify-center mt-4">
            <svg
              width="60"
              height="60"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="38" stroke="black" stroke-width="4" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56.3149 28.7293C57.2486 29.6808 57.225 31.2002 56.2621 32.123L36.2264 51.323C35.2843 52.2257 33.7871 52.2257 32.8451 51.323L23.7379 42.5957C22.775 41.673 22.7514 40.1535 23.6851 39.202C24.6188 38.2504 26.1563 38.227 27.1192 39.1498L34.5357 46.2569L52.8808 28.677C53.8437 27.7543 55.3812 27.7777 56.3149 28.7293Z"
                fill="black"
              />
            </svg>
          </div>

          <div class="text-center mt-8">
            <p class="text-2xl font-bold">{{ addSuccessed }}</p>
            <!-- <p class="text-sm mt-2">Permanently deleted</p> -->
          </div>

          <div class="mt-10 flex justify-center">
            <router-link
              :to="{ name: 'producers' }"
              class="bg-black text-center text-xs md:text-sm font-normal tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
              >Back</router-link
            >
          </div>
        </div>

        <div
          v-else
          class="bg-white p-7 w-full max-w-lg rounded-xl cursor-auto z-50"
        >
          <div class="flex justify-end">
            <button @click="showAlert = false" class="focus:outline-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.3941 19.5531L11.999 14.158L6.60398 19.5531C6.00806 20.149 5.04188 20.149 4.44596 19.5531C3.85004 18.9571 3.85004 17.991 4.44596 17.395L9.84101 12L4.44596 6.60496C3.85004 6.00904 3.85004 5.04286 4.44596 4.44694C5.04188 3.85102 6.00806 3.85102 6.60398 4.44694L11.999 9.84198L17.3941 4.44694C17.99 3.85102 18.9562 3.85102 19.5521 4.44694C20.148 5.04286 20.148 6.00904 19.5521 6.60496L14.157 12L19.5521 17.395C20.148 17.991 20.148 18.9571 19.5521 19.5531C18.9562 20.149 17.99 20.149 17.3941 19.5531Z"
                  fill="#676767"
                />
              </svg>
            </button>
          </div>

          <div class="flex justify-center mt-4">
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30 58.5C45.7401 58.5 58.5 45.7401 58.5 30C58.5 14.2599 45.7401 1.5 30 1.5C14.2599 1.5 1.5 14.2599 1.5 30C1.5 45.7401 14.2599 58.5 30 58.5Z"
                stroke="black"
                stroke-width="3"
              />
              <path
                d="M38 22L22 38"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 22L38 38"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div class="text-center mt-8">
            <p class="text-2xl font-bold">{{ addErorr }}</p>
            <!-- <p class="text-sm mt-2">Permanently deleted</p> -->
          </div>

          <div class="mt-10 flex justify-center">
            <button
              @click="showAlert = false"
              class="bg-black text-center text-xs md:text-sm font-normal tracking-widest w-32 py-3 text-surface-white rounded focus:outline-none hover:bg-white hover:text-black border hover:border-black transform duration-700"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import { required } from "vuelidate/lib/validators";

export default {
  validations: {
      producerName: { required },
      genderId: { required },
      producerDec: { required },
      imageName: { required },
  },
  created() {
    window.scrollTo(top);
  },
  mounted() {
    // Get by id
    var producerId = this.$route.params.producer;
    if (this.$route.params.producer) {
      this.$http.producersService
        .GetproducerForEdit(producerId)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.Successed = true;
          }, 1000);

          
          this.addSuccessed = res.data.result.message;
          this.data = res.data;
          this.producerName = res.data.result.producerName;
          this.genderId = res.data.result.genderId;
          this.producerDec = res.data.result.producerDec;
          this.imageName = res.data.result.imageName;
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
            this.Successed = false;
            this.addErorr = err.response.data.message;
          }, 1000);

         
        });

      this.title_page = "Edit Producer";
      this.submit_text = "Edit";
      this.showDelete = true;
    } else {
      this.title_page = "Add Producer";
      this.submit_text = "Add";
    }
  },
  data() {
    return {
      showAlert: false,
      loading: false,
      showDelete: false,
      Successed: false,
      addErorr: null,
      data: [],
      title_page: "",
      choose_emblem: "إختيار صورة",
      submit_text: "",
      file: "",
      producerName: null,
      imageName: "",
      genderId:"",
      producerDec: null,
      pagination: {},
    };
  },
  methods: {
    selectFile(input) {

      let reader = new FileReader();
      reader.onload = (event) => {
        this.imageName = event.target.result;
      };
      reader.readAsDataURL(input.target.files[0]);
    },
    submit() {
      this.showAlert = true;
      this.loading = true;

      var producer = {
        producerName: this.producerName,
        genderId:Number(this.genderId),
        producerDec: this.producerDec,
        producerImage: this.imageName,
      };

      if (this.$route.params.producer) {
        var producerinfo = {
          producerName: this.producerName,
          genderId: Number(this.genderId),
          producerDec: this.producerDec,
          ProducerImage: this.imageName,
          producerId: this.$route.params.producer,
        };

        this.$http.producersService
          .Editproducer(producerinfo)
          .then((res) => {
            this.loading = false;
            this.Successed = true;
            this.addSuccessed = res.data.result.message;
          })
          .catch((err) => {
            this.loading = false;
            this.Successed = false;
            this.addErorr = err.response.data.message;
          });
      } else
        this.$http.producersService
          .Addproducer(producer)
          .then((res) => {
            this.loading = false;
            this.Successed = true;
            this.addSuccessed = res.data.result.message;
          })
          .catch((err) => {
            this.loading = false;
            this.Successed = false;
            this.addErorr = err.response.data.message;
          });
    },
  },
};
</script>

<style>
.test {
  background-color: #035388;
  color: white;
}
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

.submit button[disabled],
.submit button[disabled]:hover,
.submit button[disabled]:active {
  border: 1px solid #ccc;
  background-color: transparent;
  color: #ccc;
  cursor: not-allowed;
}
</style>
